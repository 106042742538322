<template>
  <div>
    <div class="header">
      <div>
        <img src="" alt="" />
      </div>
      <div>混凝土配比智能设计平台</div>
      <div>|</div>
      <div>后台管理系统</div>
    </div>
    <div>
      <div class="login-container">
        <div class="title">登录</div>
        <div class="title_under_line"></div>
        <div class="input-container">
          <el-form :model="ruleForm" ref="ruleForm" :rules="rules">
            <el-form-item style="width: 150px" prop="accountNumberAndPhone">
              <el-input
                v-model.trim="ruleForm.accountNumberAndPhone"
                placeholder="用户名："
                style="width: 350px"
                maxlength="50"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item style="width: 150px" prop="pwd">
              <el-input
                v-model.trim="ruleForm.pwd"
                placeholder="密码："
                style="width: 350px"
                type="password"
                maxlength="50"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item style="width: 150px" prop="verification">
              <el-input
                v-model.trim="ruleForm.verification"
                placeholder="验证码："
                style="width: 350px"
                maxlength="4"
                clearable
              ></el-input>
              <!-- 图形验证码框 -->
              <div class="form-imageCode" @click="getImgCode">
                <img :src="imageCode" alt="" />
              </div>
            </el-form-item>
          </el-form>
          <el-button
            class="login-button"
            type="primary"
            :loading="loginBtnDisabled"
            :disabled="loginBtnDisabled"
            @click="goToLogin"
            >登录</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {},
      rules: [],
      loginBtnDisabled: false, //登录加载
      loginBtnDisabled: false, //登录禁用
      imageCode: "",
    };
  },
  mounted() {},
  methods: {
    //   登录
    goToLogin() {},
    //   获取图形验证码
    getImgCode() {},
  },
};
</script>

<style lang="less" scoped>
.login-container {
  width: 440px;
  height: 510px;
  background: #ffffff;
  box-shadow: -7px 7px 15px 0px rgba(0, 145, 85, 0.15);
  border-radius: 6px;
  position: absolute;
  right: 5%;
  top: 10%;
  .title_under_line {
    width: 30px;
    height: 2px;
    background: #9da0a5;
    margin: 20px auto;
  }
  .title {
    height: 27px;
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #222222;
    line-height: 2px;
    text-align: center;
    margin-top: 62px;
  }
  .input-container {
    width: 330px;
    margin: 40px auto;
    .form-imageCode {
      width: 100px;
      height: 40px;
      position: absolute;
      top: -10px;
      left: 250px;
      border-radius: 5%;

      img {
        width: 100%;
        height: 100%;
      }
    }
    /deep/.el-input__inner {
      border-top: none;
      border-left: none;
      border-right: none;
      padding: 0 10px;

      // &:visited {
      //   border-color: #e5faf1;
      //   background: transparent;
      // }
    }
    /deep/.el-input__inner:focus {
      border-color: #dcdfe6;
      // background: transparent;
    }
    /deep/.el-form-item__content {
      margin-top: 15px;
    }
  }
  .login-button {
    width: 330px;
    height: 46px;
    border-radius: 6px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
  }
}
</style>